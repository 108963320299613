import { AutoForm, AutoFormInputField, Button } from "@9amhealth/shared";
import type { CSSProperties, FC } from "react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { APP_CONTENT_WIDTH_WITHOUT_PADDING } from "src/constants/layout";
import Path from "src/constants/path";
import { createAppPath } from "src/lib/createAppPath";
import translate from "src/lib/translate";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import { UserPreferenceKeys } from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { toast, userPreferences } from "src/state/state";
import type { TranslationKey } from "src/types/translationKey";
import Loader from "src/ui/components/Loader/Loader";
import Track from "src/ui/components/Track/Track";
import Translate from "src/ui/components/Translate/Translate";
import { z } from "zod";
import ErrorBox from "../StyledComponents/ErrorBox";

const NicknameSchema = z.string().max(50).optional();

const ChangePreferredName: FC = () => {
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState<TranslationKey>();
  const navigate = useNavigate();

  const handleSubmit = (data: { nickname?: string }) => {
    setLoading(true);
    setErrorCode(undefined);
    const { nickname } = data;

    void userPreferences
      .updateUserPreferences({
        [UserPreferenceKeys.userNickname]: nickname ?? ""
      })
      .then(() => {
        navigate(createAppPath(Path.profile), { replace: true });
        toast.success("preferredNameUpdated");
      })
      .catch(() => {
        setErrorCode("error_generic");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      style={
        {
          "--section-max-width": `${APP_CONTENT_WIDTH_WITHOUT_PADDING}px`
        } as CSSProperties
      }
    >
      <Track event={TrackEvent.changePreferredName} type={TrackType.start} />

      <nine-spacer s="xl"></nine-spacer>
      <nine-heading>
        <h3 className="as-h4-large">
          <Translate msg="setPreferredName" />
        </h3>
      </nine-heading>

      <nine-spacer s="xl"></nine-spacer>

      <Loader active={loading}>
        <AutoForm
          schema={z.object({
            nickname: NicknameSchema
          })}
          onSubmit={handleSubmit}
          onChange={() => setErrorCode(undefined)}
        >
          <AutoFormInputField
            label={translate("preferredName")}
            name="nickname"
          />
          {errorCode && (
            <>
              <nine-spacer s="sm"></nine-spacer>
              <ErrorBox>
                <Translate msg={errorCode} />
              </ErrorBox>
            </>
          )}
          <nine-spacer s="sm"></nine-spacer>
          <Button type="submit" theme="sunrise" style={{ margin: "auto" }}>
            <Translate msg={"confirm"} />
          </Button>
        </AutoForm>
      </Loader>
    </div>
  );
};

export default ChangePreferredName;
